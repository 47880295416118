import React, { Fragment } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { InstanceName } from 'components/Login'
const Image = '/forgot_password.svg'
function Help() {
	return (
		<Fragment>
			<Navbar bg='white' className='navbarMail px-lg-5' expand='lg'>
				<Navbar.Brand className='navbarBrand' href='/'>
					<InstanceName />
				</Navbar.Brand>
			</Navbar>

			<div className='email-container'>
				<div className='row px-md-2 justify-content-center row-emailContent'>
					<div className='col-md-6 col-emailContent'>
						<h4>Is this your first time here?</h4>
						<p className='mb-2'>
							All users have received an E-Mail with Username, and Password from an email such as{' '}
							{process.env.GATSBY_MAIL || 'lmsadmin@dmimsu.edu.in'}{' '}
						</p>

						<a
							target='_blank'
							rel='noreferrer noopener'
							href='https://tinyurl.com/ygyqtak6'
							className='btn btn-queries btn-primary rounded mt-2'
						>
							For further queries
						</a>
						<Nav.Link className='navLink' href='/'>
							<i className='bx bx-chevron-left'></i> Back to login
						</Nav.Link>
					</div>
					<div className='col-md-6 order-first order-md-last emailImg'>
						<div className='text-center'>
							<img src={Image} alt='' />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}
export default Help
